header{
  justify-content: space-between;
}

.headersplit  {
  align-items: center;
}

.headersplit hr{
  height: 30px;
}

.gap{
  gap: 1rem;
}

@media (min-width: 38em) {
  .gap{
    gap: 1.8rem;
  }
}

.MuiMenu-list li:nth-child(1) {
  font-family: 'Inter' !important;
}

.MuiMenu-list li:nth-child(2) {
  font-family: 'Lora' !important;
}

.MuiMenu-list li:nth-child(3) {
  font-family: 'Inconsolata' !important;
}

.header-hr{
  border-left: 1px solid var(--clr-light-500);
  flex-grow: 1;
  margin: 0;
}

.moon-img{
  width: 20px;
}