.dictionary-card{
  margin-top: 2rem;
}

.example{
  color: #757575;
}

.example::before, .example::after{
  content: '"';
}

@media (min-width: 38em) {
  .dictionary-card{
    margin-top: 2.625rem;
  }
}

.part-of-speech p {
  font-size: var(--fs-500);
  font-weight: var(--fw-600);
}

.dictionary-card .split{
  align-items: 'center';
  justify-content: 'center';
  gap: 1.2rem;
}

hr{
  flex-grow: 1;
  border: none;
  margin-top: 11px;
  border-top: 1px solid var(--clr-hr);
}


.h2-title{
  margin-top: 2rem;
  
}

.link{
  font-weight: var(--fw-600);
  color: var(--clr-accent-100);
}