.input-container {
  margin-top: 1.5rem;
  position: relative;
  width: 100%;
}

@media (min-width: 38em) {
  .input-container{
    margin-top: 3.25rem;
  }
}

.input-container input {
  padding-left: 1rem;
  width: 100%;
  background-color: var(--clr-bg-input);
  color: var(--clr-font-basic);
  width: 100%;
  outline:none;
  border: none;
  border-radius: 1rem;
  height: 3rem;
}

.input-container input:active{
  outline-width: 0;  
  border:1px solid var(--clr-accent-100);
}

.input-container img {
  position: absolute;
  right: 1.4rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #999;
}


.input-error input{
  border: 1px solid var( --clr-error-100);
}

.error-message{
  margin-top:0.25rem;
  color: var( --clr-error-100);
}